import React, { useState } from "react"
import PropTypes from "prop-types"

import language from "@language"
import UniversalLink from "@components/common/UniversalLink"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"

import "./style.scss"

const ContactLocations = props => {
  const constantizeLocation = location => {
    if (!location) return
    return location.title
  }
  const [section, setSection] = useState(constantizeLocation(props.locations[0]))

  return (
    <div className="well">
      <div className="page-wrap contact-locations">
        <HeadingText className="mb-10px" size="md" color="dark-gray" text={language[props.language].contact_information} />

        <div className="contact-locations__menu mb-20px">

          {
            props.locations.map(location => {
              return <button
                className="contact-locations__button"
                onClick={() => setSection(constantizeLocation(location))}
              >
                <BodyText
                  size="lg"
                  color={section === constantizeLocation(location) ? "dark" : "light-gray"}
                  text={location.title}
                />
              </button>
            })
          }
        </div>
        <div className="contact-locations__content">
          {
            props.locations.map(location => {

              return section === constantizeLocation(location) && (
                <>
                  {location.acf.telephone && (
                    <>
                      <div className="mb-20px">
                        <BodyText element="span" color="dark-gray" size="lg">
                          {language[props.language].main_telephone}{': '}
                        </BodyText>
                        <UniversalLink href={`tel:${((location.acf.telephone || "").match(/\d/g) || []).join("")}`} size="lg">
                          <BodyText element="span" color="primary" size="lg">
                            {location.acf.telephone}
                          </BodyText>
                        </UniversalLink>
                      </div>
                    </>
                  )}

                  {

                    !!(location.acf.otherTelephones || []).length &&
                    location.acf.otherTelephones.map(tel => {
                      return <div className="mb-20px">
                        <BodyText element="span" color="dark-gray" size="lg">
                          {tel.telephoneLabel}{': '}
                        </BodyText>
                        <BodyText element="span" color="dark-gray" size="lg">
                          {tel.telephoneNumber}
                        </BodyText>
                      </div>

                    })
                  }
                </>


              )
            })
          }

        </div>
      </div>
    </div>
  )
}

ContactLocations.propTypes = {
  locations: PropTypes.array,
  language: PropTypes.string,
}
ContactLocations.defaultProps = {
  locations: [],
  language: "es",
}

export default ContactLocations
