import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import language from "@language"
import BrandButton from "@components/common/BrandButton"
import BodyText from "@components/common/BodyText"

import { useFormik } from "formik"
import axios from "axios"
import classnames from "classnames"

import "./style.scss"
import FormInput from "../../../../components/common/FormInput"
import FormTextArea from "../../../../components/common/FormTextArea"

// https://medium.com/@ronnyangelofreites/connecting-contact-form-7-wordpress-plugin-with-gatsbyjs-1c68f472b0fd

const ContactForm = props => {
  const WEBSITE_URL = process.env.GATSBY_WORDPRESS_URL
  const FORM_ID = process.env.GATSBY_CONTACT_FORM_ID // The contact form ID (found in the shortcode in wp admin)

  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const honeypotRef = React.createRef()
  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `${WEBSITE_URL}/wp-json/jwt-auth/v1/token`,
      data: {
        username: process.env.GATSBY_CONTACT_USERNAME,
        password: process.env.GATSBY_CONTACT_PASSWORD,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [WEBSITE_URL])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const { handleChange, isSubmitting, values, handleSubmit } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      telephone: "",
      subject: "",
      message: "",
    },
    onSubmit: (
      { first_name, last_name, email, telephone, subject, topic, message },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      if (document.querySelector("#title").value) return // honeypot catch - do not submit
      // here we created a FormData field for each form field
      const bodyFormData = new FormData()
      bodyFormData.set("first_name", first_name)
      bodyFormData.set("last_name", last_name)
      bodyFormData.set("email", email)
      bodyFormData.set("telephone", telephone)
      bodyFormData.set("subject", subject)
      bodyFormData.set("message", message)

      // here we sent
      axios({
        method: "post",
        url: `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    // setTimeout(() => {
    //   // this will reset messageSent and isSuccessMessage state
    //   setMessageSent(false)
    //   setIsSuccessMessage(false)
    // }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <form
      className={classnames("contact-form", props.className)}
      onSubmit={handleSubmit}
    >
      <fieldset className="contact-form__fieldset">
        <FormInput
          id="title"
          name="title"
          type="text"
          inputRef={honeypotRef}
          ariaHidden={true}
          style={{ position: "absolute", left: "-5000px" }}
          tabIndex="-1"
        />

        <div className="contact-form__double-input-row">
          <div className="contact-form__field-group">
            <label className="sr-only" htmlFor="first_name">
              {language[props.language].name}
            </label>
            <FormInput
              id="first_name"
              name="first_name"
              type="text"
              onChange={handleChange}
              placeholder={`${language[props.language].name}*`}
              value={values.first_name}
              required={true}
            />
          </div>
          <div className="contact-form__field-group">
            <label className="sr-only" htmlFor="last_name">
              {language[props.language].last_name}
            </label>
            <FormInput
              id="last_name"
              name="last_name"
              type="text"
              onChange={handleChange}
              value={values.last_name}
              placeholder={`${language[props.language].last_name}*`}
              required={true}
            />
          </div>
        </div>
        <div className="contact-form__field-group">
          <label className="sr-only" htmlFor="email">
            {language[props.language].email}
          </label>
          <FormInput
            id="email"
            name="email"
            type="email"
            onChange={handleChange}
            value={values.email}
            placeholder={`${language[props.language].email}*`}
            required={true}
          />
        </div>
        <div className="contact-form__field-group">
          <label className="sr-only" htmlFor="telephone">
            {language[props.language].telephone_number}
          </label>
          <FormInput
            id="telephone"
            name="telephone"
            type="text"
            onChange={handleChange}
            placeholder={language[props.language].telephone_number}
            value={values.telephone}
          />
        </div>
        <div className="contact-form__field-group">
          <label className="sr-only" htmlFor="subject">
            {`${language[props.language].topic}/${
              language[props.language].subject
            }`}
          </label>
          <FormInput
            id="subject"
            name="subject"
            type="text"
            onChange={handleChange}
            value={values.subject}
            placeholder={`${language[props.language].topic}/${
              language[props.language].subject
            }*`}
            required={true}
          />
        </div>
        <div className="contact-form__field-group">
          <label className="sr-only" htmlFor="message">
            {language[props.language].message}
          </label>
          <FormTextArea
            className="mb-40px"
            id="message"
            name="message"
            type="text"
            onChange={handleChange}
            value={values.message}
            placeholder={`${language[props.language].message}*`}
            required={true}
          />
        </div>
      </fieldset>
      <div>
        <BrandButton
          color="primary"
          type="submit"
          disabled={isSubmitting}
          text={language[props.language].send_message}
        />
      </div>
      {messageSent && isSuccessMessage && (
        <BodyText
          className="contact-form__message"
          color="primary"
          size="lg"
          text={language[props.language].message_sent}
        />
      )}
      {messageSent && !isSuccessMessage && (
        <BodyText
          className="contact-form__message"
          color="error"
          size="lg"
          text={language[props.language].error_occured}
        />
      )}
    </form>
  )
}

ContactForm.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
}
ContactForm.defaultProps = {
  language: "es",
}

export default ContactForm
