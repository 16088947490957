import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import gql from "graphql-tag"
import language from "@language"
import Layout from "@components/layout"
import SEO from "@components/seo"
import withPreview from "@components/withPreview"
import { filterPageForTemplate } from "@templates/utilities/templateUtils"
import PageCarousel from "@components/PageCarousel"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"

import LocationCard from "@components/LocationCard"

import BrandButton from "@components/common/BrandButton"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"

import ContactForm from "./components/ContactForm"
import ContactLocations from "./components/ContactLocations"

import "./style.scss"

const ContactPage = props => {
  const page =
    !!props.preview && props.preview.page
      ? props.preview.page.preview.node
      : filterPageForTemplate(
        props.data.allWpPage.edges,
        props.pageContext.wordpressUri
      )

  return (
    <Layout>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="page-fade-in">

        { console.log(page) }

        <SimpleBreadcrumbHero
          overline={page.acfContact.contacthero.overline}
          pageTitle={page.acfPageMeta.metaTitle}
          title={page.acfContact.contacthero.title}
          image={page.acfContact.contacthero.contactheroimage}  
          overlayType="full"
        />


        <ContactLocations locations={page.acfContact.clocationCarousel.locations} />

        <div className="page-wrap">
          <div className="mb-30px">
            <div className="contact__form-lead-text mb-60px">
              {page.acfContact.form.overline && (
                <HeadingText
                  className="simple-breadcrumb-hero__overline"
                  color="dark"
                  size="xs"
                  text={page.acfContact.form.overline}
                />
              )}
              {page.acfContact.form.title && (
                <HeadingText
                  className="simple-breadcrumb-hero__title"
                  color="primary"
                  size="xl2"
                  text={page.acfContact.form.title}
                ></HeadingText>
              )}
              {page.acfContact.form.subcopy && (
                <BodyText
                  className="simple-breadcrumb-hero__subcopy"
                  color="dark"
                  size="md2"
                  text={page.acfContact.form.subcopy}
                ></BodyText>
              )}
            </div>

            <ContactForm className="contact__form" />
          </div>
        </div>
        <div className="contact__carousel-section bg-light-gray">
          <div className="page-wrap content-spacing--lg">
            <PageCarousel
              childKeyIdentifier="location-card"
              slides={(page.acfContact.clocationCarousel.locations || [])
                .filter(l => l.acf.publishFinal)
                .map((location, index) => {
                  return (
                    <LocationCard
                      key={`contact-location-card--${index}`}
                      title={location.title}
                      telephone={location.acf.telephone}
                      city={location.acf.city}
                      stateAbbreviation={location.acf.stateAbbreviation}
                      streetAddress={location.acf.streetAddress}
                      typeName={(location.acf.type || {}).name}
                      titlePrefix={(location.acf.type || {}).description}
                      zipCode={location.acf.zipCode}
                      uri={location.uri}
                      image={location.acf.locationImage}
                    />
                  )
                })}
              contentSlide={
                <div className="contact__copy">
                  {page.acfContact.clocationCarousel.title && (
                    <div>
                      <HeadingText
                        className="contact__title mb-20px"
                        text={language[props.language].locations}
                        size="md"
                        element="h2"
                        color="dark"
                      />
                      <HeadingText
                        className="contact__title mb-40px"
                        text={page.acfContact.clocationCarousel.title}
                        size="xl"
                        element="p"
                        color="primary"
                      />
                    </div>
                  )}
                  {page.acfContact.clocationCarousel.cta && (
                    <BrandButton
                      className="contact__cta--desktop"
                      href={page.acfContact.clocationCarousel.cta.url}
                      text={page.acfContact.clocationCarousel.cta.title}
                    />
                  )}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "contact" } } }) {
      edges {
        node {
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfContact {
            contacthero {
              overline
              title
              contactheroimage {
                altText
                sourceUrl
              }
            }
            fieldGroupName
            form {
              overline
              subcopy
              title
            }
            clocationCarousel {
              locations {
                ... on WpLocation {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    city
                    stateAbbreviation
                    streetAddress
                    telephone
                    otherTelephones {
                      telephoneLabel
                      telephoneNumber
                    }
                    type {
                      name
                      description
                    }
                    zipCode
                    locationImage {
                      altText
                      sourceUrl
                    }
                  }
                }
              }
              title
              cta {
                url
                title
              }
            }
          }
        }
      }
    }
  }
`

// Sorry, have to manually reconstruct the preview queries in the Graphiql tool on wordpress...
const PREVIEW_QUERY = gql`
  query PreviewContactPageQuery($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          uri
          acfPageMeta {
            metaDescription
            metaImage {
              altText
              sourceUrl
              uri
            }
            metaTitle
          }
          acfContact {
            fieldGroupName
            form {
              overline
              subcopy
              title
            }
            clocationCarousel {
              locations {
                ... on Location {
                  id
                  title
                  uri
                  acf {
                    publishFinal
                    city
                    stateAbbreviation
                    streetAddress
                    telephone
                    otherTelephones {
                      telephoneLabel
                      telephoneNumber
                    }
                    type {
                      name
                      description
                    }
                    zipCode
                    locationImage {
                      altText
                      sourceUrl
                    }
                  }
                }
              }
              title
              cta {
                url
                title
              }
            }
          }
        }
      }
    }
  }
`

ContactPage.propTypes = {
  language: PropTypes.string
}
ContactPage.defaultProps = {
  language: "es",
}

export default withPreview({ preview: PREVIEW_QUERY })(ContactPage)
