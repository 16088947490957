import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./style.scss"

const FormTextArea = props => {
  return (
    <textarea
      className={classnames("form-textarea", props.className)}
      ref={props.inputRef}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      style={props.style}
      type={props.type}
      aria-hidden={props.ariaHidden}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
    />
  )
}

FormTextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.object,
  style: PropTypes.object,
  type: PropTypes.string,
  ariaHidden: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}
FormTextArea.defaultProps = {
  type: "text",
}

export default FormTextArea
